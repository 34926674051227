<template>
	<Dialog :visible="isShown" :modal="true" :closable="false" class="w-md-75 w-lg-50 w-100">
		<template #header>
			<h6 class="text-center">Family Members</h6>
		</template>
		<div class="row" >
			<div class="col-md-6" v-for="(singleMember , index) in members" :key="index">
				<div class="card mt-3  p-3 bg-light">
					<div class="d-block mt-3">
						<span class="fw-bolder">Family role : </span>
						{{$root['displayFamilyRole'](singleMember.familyRole) }}
						<hr/>
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">Name :</span>
						{{ singleMember.firstName + " " + singleMember.lastName }}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">DOB :</span>
						{{(singleMember.dob!==null) ? singleMember.dob : "--"  }}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">Address :</span>
						{{singleMember.addressLine1 + " " + singleMember.addressLine2  }}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">District :</span>
						{{singleMember.district +"-" + singleMember.pincode}}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">State :</span>
						{{$root['getIndianState'](singleMember.state)}}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">Country :</span>
						{{ getCountryNameByCode(singleMember.country) }}
					</div>
					<div class="d-block mt-3">
						<span class="fw-bolder">Email :</span>
						{{( singleMember.email!==null) ? singleMember.email  : "---" }}
					</div>
					<div class="d-block mt-3 ">
						<span class="fw-bolder">Phone :</span>
						{{singleMember.phone}}
					</div>
					<div class="d-block mt-3">
						<span class="fw-bolder">Whatsapp :</span>
						{{singleMember.whatsappNumber!==null ? singleMember.whatsappNumber : "---"}}
					</div>
					<div class="d-block mt-3">
						<span class="fw-bolder">Code :</span>
						{{ singleMember.code  }}
					</div>
				</div>
			</div>
		</div>
		
		<Button class="w-100 mt-3" label="Cancel"
		        severity="danger"
		        icon="pi pi-times"
		        @click="$emit('close')"/>
	</Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import {getCountryNameByCode} from "../../utils/countries";
import {FAMILY_ROLES} from "@/dashboard/members/members";

export default{
	components:{Dialog,Button},
	data(){
		return{
			familyRole:[...FAMILY_ROLES],
			
		}
	},
	emits:['close'],
	props:{
		isShown:{
			type:Boolean,
			default(){
				return false;
			}
		},
		members:{
			type:Array,
			default(){
				return [];
			}
		}
	},
	methods:{
		getCountryNameByCode,
		
	},
	
	computed:{
	
	}
}

</script>



<style scoped lang="scss">

</style>