export const TIME_BASED_SELECTION = [
    {label: "Week", value: "WEEK"},
    {label: "15 days", value: "TWO_WEEKS"},
    {label: "1 Month", value: "MONTH"},
    {label: "3 Months", value: "THREE_MONTHS"},
]

export const DASHBOARD_DURATIONS = [
    { label: "This Week", value: "WEEK" },
    { label: "Last 15 days", value: "FIFTEEN_DAYS" },
    { label: "This month", value: "MONTH" },
    { label: "Last 3 Months", value: "THREE_MONTH" },
    { label: "Last 6 Months", value: "SEMESTER" },
    { label: "This Year", value: "YEAR" },
    { label: "Last Year", value: "LAST_YEAR" },
]