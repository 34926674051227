<template>
    <Dialog :visible="isVisible"
            :modal="true"
            class="w-md-75 w-lg-50"
            :closable="false">
        <template #header>
	        <div class="row w-100">
		        <div class="col-6 text-center p-3"
		             :class="isInsider ? 'bg-dark text-white' : 'text-dark'"
		             @click="handleInsider">
			        <h4 class="fw-bolder my-0 cursor-pointer"
			            :class="isInsider ? 'text-white' : 'text-dark'">Local Priest</h4>
		        </div>
		        <div class="col-6 text-center p-3"
		             :class="isOutsider ? 'bg-dark text-white' : 'text-dark'"
		             @click="handleOutsider">
			        <h4 class="fw-bolder my-0 cursor-pointer"
			            :class="isOutsider ? 'text-white' : 'text-dark'">Visiting Priest</h4>
		        </div>
	        </div>
        </template>
        <div class="row">
            <div class="col-md-12 mt-3" v-if="isInsider===true">
	            <FormLabel label-text="Search Priest"/>
                <AutoComplete class="w-100"
                              v-model="insider.query"
                              inputClass="w-100"
                              :suggestions="foundPriests"
                              placeholder="To: Type to search a Priest"
                              optionLabel="label"
                              @complete="search"
                              dataKey="code"/>
            </div>
            <div class="col-12 mt-3" v-if="isInsider===true">
	            <FormLabel label-text="Date of baptism"/>
                <DatePicker  class="w-100"
                             v-model="insider.dateOfBaptism"
                             :maxDate="maxDate"
                             placeholder="Date of Baptism"/>
            </div>
            <div class="col-md-6 mt-3" v-if="isOutsider===true">
	            <FormLabel label-text="Priest First Name"/>
                <InputText class="w-100"
                           v-model="outsider.firstName"
                           placeholder="Firstname"/>
            </div>
            <div class="col-md-6 mt-3"  v-if="isOutsider===true">
	            <FormLabel label-text="Priest Last Name"/>
                <InputText class="w-100"
                           v-model="outsider.lastName"
                           placeholder="Lastname"/>
            </div>
            <div class="col-12 mt-3" v-if="isOutsider===true">
	            <FormLabel label-text="Date of baptism"/>
                <DatePicker  class="w-100"
                             v-model="outsider.dateOfBaptism"
                             :maxDate="maxDate"
                             placeholder="Date of Baptism"/>
            </div>
        </div>
        <template #footer>
            <Button label="Cancel"
                    class="w-100"
                    @click="$emit('closeDialog', null)"
                    severity="danger"
                    icon="pi pi-times"/>
        
        
            <Button label="Baptize"
                    class="w-100"
                    severity="info"
                    @click="baptize"
                    icon="pi pi-save"/>
                
        </template>
    </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import DatePicker from "primevue/datepicker";
import {BAPTIZED_MODEL} from "@/dashboard/church/church";
import {INSIDER_MODEL, OUTSIDER_MODEL} from "@/dashboard/church/church-activities/utils/visiting_model";
import {convertUiToDate} from "@/utils/AppFx";
import FormLabel from "@/components/FormLabel.vue";
export default {
    name: "VisitMemberDialog",
    components : {
	    FormLabel,
        Dialog,
        Button,
        AutoComplete,
        DatePicker,
        InputText
    },
    props:{
        isVisible : {
            type :Boolean,
            default(){
                return false;
            }
        },
        memberToBeBaptized : {
            type:Object,
            default(){
                return null;
            }
        }
    },
    emits : ['closeDialog', 'save'],
    data(){
        return{
            foundPriests : [],
            isInsider  : true,
            isOutsider : false,
            insider :{...INSIDER_MODEL},
            outsider : {...OUTSIDER_MODEL},
	        maxDate: new Date(),
        }
    },
    methods: {
        handleInsider : function(){
            if(this.isInsider===false){
                this.isInsider = true;
                this.isOutsider = false;
            }
        },
        handleOutsider : function(){
            if(this.isOutsider===false){
                this.isOutsider = true;
                this.isInsider = false;
            }
            
        },
        search : function(event){
            let data = {
                query: event.query,
                churchFunction: "PRIEST"
            }
            data = this.$root['addInstitutionId'](data);
            data = {
				...data,
	            dateOfBaptism: convertUiToDate(this.insider.dateOfBaptism)
            };
            this.$api.post("/institution/search-priest", data).then(response=>{
                this.foundPriests = response.map(singleMember=>{
                    return {
                        label:singleMember.firstName+ " "+singleMember.lastName,
                        code : singleMember.id,
                    }
                });
                this.insider = {...INSIDER_MODEL};
            }).catch(error=>{
                this.$root['handleApiError'](error, "Member searching");
            })
        },
        baptize : function(){
            //  console.log(this.memberToBeBaptized, "umu jeune tugira tubatize");
            if(this.isInsider===true){
                let data = {
                    baptismalCandidateId : this.memberToBeBaptized.id,
                    dateOfBaptism : this.insider.dateOfBaptism,
                    baptist : this.insider.query.code
                };
                this.$api.post("/institution/member-baptism", data).then(response=>{
                    this.$root['showAlert']('success', "Baptism", response.message);
                    this.insider = {...INSIDER_MODEL};
                    this.$emit("closeDialog", response.object);
                }).catch(error=>{
                    this.$root['handleApiError'](error, "Christening");
                })
            }else if(this.isOutsider===true){
                let data = this.$root["addInstitutionId"](this.outsider);
                console.log(data);
                this.$api.post("/visit-member/save", data).then(response=>{
                     this.outsider = {...OUTSIDER_MODEL}
                    this.$root['showAlert']("success", "Baptism", response.message);
                }).catch(error=>{
                    this.$root['handleApiError'](error, "Baptism");
                })
            }
            
        }
    },
    
}
</script>



<style scoped lang="scss">

</style>
