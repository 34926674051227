<script>
import DashboardCard from "@/dashboard/containers/DashboardCard.vue";
import PaginatedTable from "@/dashboard/utils/paginator/PaginatedTable.vue";
import {PAGE_MODEL} from "@/utils/DEFAULT_MODELS";
import {AVAILABLE_LANGUAGES, SUBSCRIPTION_PLANS} from "@/dashboard/organization/Organization";

export default {
	name: "BirthdaysList",
	components: {PaginatedTable, DashboardCard},
	data(){
		return {
			tableData: {...PAGE_MODEL},
			searchModel: {
				multiple: false,
				start: null,
				end: null,
				size: 5,
				page: 1
			},
			selectOptions: [
				{ label: "Single Date", value: false},
				{ label: "Date Gap", value: true},
			],
		}
	},
	computed: {
		tableHeaders: function(){
			return {
				"Full name":{
					rows:[
						{ type: "StringArray", attribute: ["firstName", "lastName"]},
						{ type: "Date", attribute: "dob", label: "DOB"},
						{ type: "String", attribute: "email"},
						{ type: "PlainNumber", attribute: "phone", label: "Phone: "}
					]
				},
				"Partner Code":{
					rows:[
						{ type: "String", attribute: "code"},
					]
				},
				"Info":{
					rows:[
						{ label:"State", type: "String", attribute: "state" },
						{ label:"District", type: "String", attribute: "district" },
						{ label:"Subscription", type: "Select", attribute: "subscription", options: [...SUBSCRIPTION_PLANS]},
						{ label:"Language", type: "Select", attribute: "language", options: [...AVAILABLE_LANGUAGES]},
					].filter(row=>{
						if(this.isInstitutionChurch) return row.attribute!=="subscription";
						return true
					})
				},
				"Created on":{
					rows:[
						{ type: "Date", attribute: "creationDate"}
					]
				}
			};
		},
		extraInputs: function(){
			return [
				{ type: "SELECT", model: "multiple", placeholder: "Select", options: this.selectOptions, styleClass:'col-md-4 mb-4'},
				{ type: "DATE", model: "start", placeholder: "Date Start", format: "dd-MM", styleClass:'col-md-8 mb-4'},
				{ type: "DATE", model: "end", placeholder: "Date Start", format: "dd-MM", styleClass:'col-md-8 mb-4', shownIf: {model: "multiple", values: [true]}},
			].map(input=>{
				if(this.searchModel.multiple) input.styleClass = "col-md-4 mb-4";
				else input.styleClass = "col-md-6 mb-4";
				return input;
			});
		},
		isInstitutionChurch: function(){
			return this.isChurch;
		}
	},
	methods: {
		makeSearch: function(){
			let data = this.$root['addInstitutionId']({...this.searchModel});
			if(!this.searchModel.multiple) delete data.end;
			this.$api.post("/institution/list-birthdays", data).then(response=>{
				this.tableData = response;
			}).catch(error=>{
				this.$root['handleApiError'](error, "Birthdays listing.");
			});
		}
	},
	props: {
		isChurch: {
			type: Boolean,
			required: true,
			default(){
				return false;
			}
		},
	}
}
</script>

<template>
	<div class="d-block position-relative">
		<DashboardCard :header-title="'Marriages'"
		               :refreshButton="false">
			<PaginatedTable :table-data="tableData"
			                :has-query-input="false"
			                :table-headers="tableHeaders"
			                :paginated="true"
			                :extra-inputs="extraInputs"
			                :date-search="false"
			                :search-model="searchModel"
			                @search="makeSearch"/>
		</DashboardCard>
	</div>
</template>

<style scoped lang="scss">

</style>