export const SCHEDULER_TIMINGS = [
    { "label": "Day", "value": "EVERY_DAY" },
    { "label": "Monday", "value": "EVERY_MONDAY" },
    { "label": "Tuesday", "value": "EVERY_TUESDAY" },
    { "label": "Wednesday", "value": "EVERY_WEDNESDAY" },
    { "label": "Thursday", "value": "EVERY_THURSDAY" },
    { "label": "Friday", "value": "EVERY_FRIDAY" },
    { "label": "Saturday", "value": "EVERY_SATURDAY" },
    { "label": "Sunday", "value": "EVERY_SUNDAY" },
    { "label": "Month", "value": "EVERY_MONTH" }
]